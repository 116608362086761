import type { SelectListOption } from "@/types/models/select-list-item";
import type { SpecificProductViewModel } from "@/types/models/product/specific-product-view-model";
import BaseService from "./base-service";

class ProductService extends BaseService {
    async getAllProductVariants() {
        return await this.get<SelectListOption[]>('/product/getAllProductVariants');
    }

    async getSpecificProductVariants(isFRClear: boolean) {
        return await this.get<SpecificProductViewModel[]>('/product/getSpecificProductVariants', { params: { isFRClear } });
    }
}

export default new ProductService();