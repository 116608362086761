<template>
    <header>
        <BNavbar toggleable="md" container :class="[{'navbar-index': isStoreIndex}, 'bg-default top-bar']">
            <BNavbarNav class="ms-auto">
                <BNavItem to="/steel-coverage-calculator">
                    Steel Coverage Calculator
                </BNavItem>
                <BNavItem v-if="!isAuthenticated" to="/login">
                    Log in
                </BNavItem>

                <BNavItemDropdown class="login-item" :text="user.name" v-if="isAuthenticated && user" id="user">
                    <BDropdownHeader>{{ user.email }}</BDropdownHeader>

                    <BDropdownItem to="/customer/info"><font-awesome icon="user" class="fa-fw" /> My Account</BDropdownItem>

                    <BDropdownDivider></BDropdownDivider>

                    <BDropdownItemButton @click="logout"><font-awesome icon="power-off" class="fa-fw" /> Log Out</BDropdownItemButton>
                </BNavItemDropdown>

                <BNavText id="topcartlink" @mouseenter="toogleFlyoutCart(true)" @mouseleave="toogleFlyoutCart(false)" class="nav-item cart-link-item">
                    <NuxtLink to="/cart" class="nav-link cart-link hidden-xsd-none d-sm-block ico-cart">
                        <font-awesome icon="basket-shopping" aria-hidden="true" />
                        <span class="cart-qty badge rounded-pill">{{ cartItemCount }}</span>
                    </NuxtLink>
                </BNavText>
                <ClientOnly>
                    <template #fallback>
                        <li class="nav-item">
                            <a data-calltrk-noswap class="nav-link" href="tel:(888) 816-7468"> (888) 816-7468 </a>
                        </li>
                    </template>
                    <BNavItem href="tel:(888) 816-7468">
                        (888) 816-7468
                    </BNavItem>
                </ClientOnly>
            </BNavbarNav>

            <div id="flyout-cart" :class="[{'active': showFlyoutCart}, 'flyout-cart']" @mouseenter="toogleFlyoutCart(true)" @mouseleave="toogleFlyoutCart(false)">
                <div class="mini-shopping-cart">
                    <div class="count" v-if="cartItemCount">
                        There are <a href="/cart">{{ cartItemCount }} item(s)</a> in your cart.
                    </div>
                    <div class="count" v-else>
                        You have no items in your shopping cart.
                    </div>
                    <div class="items-wrapper" v-if="cartItemCount">
                        <div class="items">
                            <div class="item first" v-for="(item, index) in cartItems">
                                <div class="picture">
                                    <a href="/">
                                        <NuxtImg width="59" height="70" :src="item.productImage" alt="" />
                                    </a>
                                </div>
                                <div class="product">
                                    <div class="name">
                                        {{item.productName}}
                                    </div>
                                    <div class="attributes">
                                        Variant: {{resolveProductVariant(item.productVariantName)}}
                                    </div>
                                    <div class="price">Unit price: <span>${{ item.price.toFixed(2) }}</span></div>
                                    <div class="quantity">Quantity: <span>{{ item.quantity }}</span></div>
                                </div>
                            </div>
                        </div>
                        <div class="totals">Sub-Total: <strong>${{cartSubTotal.toFixed(2)}}</strong></div>
                        <div class="buttons">
                            <NuxtLink class="btn btn-primary cart-button" to="/cart">
                                Go to cart
                            </NuxtLink>
                        </div>
                    </div>
                </div>
            </div>
        </BNavbar>

        <BNavbar toggleable="md" container :class="[{'navbar-index': isStoreIndex}, 'bg-default header']">
            <div class="navbar-header">
                <NuxtLink class="navbar-brand active me-4" aria-label="Flameoff Coatings home page" to="/"></NuxtLink>
            </div>

            <BNavbarNav class="primary-nav">
                <ClientOnly>
                    <template #fallback>
                        <li class="nav-item dropdown">
                            <button class="btn btn-md btn-link nav-link dropdown-toggle" type="button" id="product" aria-expanded="false" aria-haspopup="menu">Products</button>
                        </li>
                        <li class="nav-item dropdown">
                            <button class="btn btn-md btn-link nav-link dropdown-toggle" type="button" id="substrates" aria-expanded="false" aria-haspopup="menu">Substrates</button>
                        </li>
                        <li class="nav-item dropdown">
                            <button class="btn btn-md btn-link nav-link dropdown-toggle" type="button" id="resources" aria-expanded="false" aria-haspopup="menu">Resources</button>
                        </li>
                        <li class="nav-item dropdown">
                            <button class="btn btn-md btn-link nav-link dropdown-toggle" type="button" id="about" aria-expanded="false" aria-haspopup="menu">About</button>
                        </li>
                    </template>
                    <BNavItemDropdown text="Products" id="product">
                        <BDropdownItem to="/intumescent-fire-resistant-paint">Fire Barrier Paint</BDropdownItem>
                        <BDropdownDivider></BDropdownDivider>
                        <BDropdownItem to="/fire-retardant-clear-spray">FR Clear</BDropdownItem>
                    </BNavItemDropdown>

                    <BNavItemDropdown menu-class="multi-column" text="Substrates" id="substrates">
                        <template #default>
                            <div class="row mx-0">
                                <div class="col-6">
                                    <BDropdownHeader>
                                        <label>FIRE BARRIER PAINT</label>
                                    </BDropdownHeader>
                                </div>
                                <div class="col-6">
                                    <BDropdownHeader>
                                        <label>FR CLEAR</label>
                                    </BDropdownHeader>
                                </div>
                            </div>
                            <div class="row mx-0">
                                <div class="col-6">
                                    <NuxtLink class="dropdown-item" to="/applying-fire-rated-intumescent-paint-on-steel">Steel</NuxtLink>
                                </div>
                                <div class="col-6">
                                    <NuxtLink class="dropdown-item" to="/applying-fire-retardant-coatings-on-wood">Wood</NuxtLink>
                                </div>
                            </div>
                            <div class="dropdown-divider"></div>
                            <div class="row mx-0">
                                <div class="col-6">
                                    <NuxtLink class="dropdown-item" to="/applying-fire-rated-intumescent-paint-on-gypsum">Gypsum/Drywall</NuxtLink>
                                </div>
                                <div class="col-6">
                                    <NuxtLink class="dropdown-item" to="/applying-flame-retardant-coatings-on-fabrics">Fabrics</NuxtLink>
                                </div>
                            </div>
                            <div class="dropdown-divider"></div>
                            <div class="row mx-0">
                                <div class="col-6">
                                    <NuxtLink class="dropdown-item" to="/applying-fire-rated-intumescent-paint-on-wood">Wood</NuxtLink>
                                </div>
                            </div>
                        </template>
                    </BNavItemDropdown>
                    <BNavItemDropdown text="Resources" id="resources">
                        <BDropdownItem to="/steel-coverage-calculator">Steel Coverage<br>Calculator</BDropdownItem>
                        <BDropdownDivider></BDropdownDivider>
                        <BDropdownItem to="/steel-dimensions-table">Steel Dimensions<br>Table</BDropdownItem>
                        <BDropdownDivider></BDropdownDivider>
                        <BDropdownItem to="/testing">Testing</BDropdownItem>
                        <BDropdownDivider></BDropdownDivider>
                        <BDropdownItem to="/leed">LEED</BDropdownItem>
                        <BDropdownDivider></BDropdownDivider>
                        <BDropdownItem to="/faq">FAQ</BDropdownItem>
                        <BDropdownDivider></BDropdownDivider>
                        <BDropdownItem to="/flameoff-partnership">Partnership</BDropdownItem>
                        <BDropdownDivider></BDropdownDivider>
                        <BDropdownItem href="https://flameoffgetcertified.tizbi.com" target="_blank" rel="noreferrer noopener nofollow">Get Certified</BDropdownItem>
                        <BDropdownDivider></BDropdownDivider>
                        <BDropdownItem href="https://lunchandlearn.flameoffcoatings.com" target="_blank" rel="noreferrer noopener nofollow">Lunch and Learn</BDropdownItem>
                        <BDropdownDivider></BDropdownDivider>
                        <BDropdownItem href="https://www.youtube.com/@flameoffcoatingsinc5470/videos" target="_blank" rel="noreferrer noopener nofollow">Media Library</BDropdownItem>
                    </BNavItemDropdown>

                    <BNavItemDropdown text="About" id="about">
                        <BDropdownItem to="/about/company">Company</BDropdownItem>
                        <BDropdownDivider></BDropdownDivider>
                        <BDropdownItem to="/intumescent-paint-case-studies">Case Studies</BDropdownItem>
                        <BDropdownDivider></BDropdownDivider>
                        <BDropdownItem to="/articles">Articles</BDropdownItem>
                        <BDropdownDivider></BDropdownDivider>
                        <BDropdownItem to="/company-news">Company News</BDropdownItem>
                    </BNavItemDropdown>
                </ClientOnly>
                <BNavItem to="/contact-us">
                    Contact Us
                </BNavItem>
            </BNavbarNav>
            <BNavbarNav class="navbar-right">
                <BNavText id="topcartlink" @mouseenter="toogleFlyoutCart(true)" @mouseleave="toogleFlyoutCart(false)" class="nav-item cart-link-item">
                    <NuxtLink to="/cart" class="nav-link cart-link hidden-xsd-none ico-cart">
                        <font-awesome icon="basket-shopping" class="header-icon" />
                        <span class="cart-qty bar-badge rounded-pill">{{ cartItemCount }}</span>
                    </NuxtLink>
                </BNavText>
                <ClientOnly>
                    <BNavItem class="text-phone" href="tel:(888) 816-7468">
                        <nuxt-icon name="phone" filled />
                        <span>(888) 816-7468</span>
                    </BNavItem>
                </ClientOnly>
                <BNavForm class="order-now-item">
                    <BButton variant="primary" class="order-now" @click="openOrderNow()">Order Now</BButton>
                </BNavForm>
            </BNavbarNav>
        </BNavbar>

        <button type="button" aria-label="mobile-menu-toogle" :class="[{'navbar-index': isStoreIndex, 'active': activeMobileMenu }, 'overlay-menu-toggle']" @click="activeMobileMenu = !activeMobileMenu">
            <span class="icon-bar"></span>
            <span class="icon-bar"></span>
            <span class="icon-bar"></span>
        </button>

        <div :class="['overlay-menu', {'open': activeMobileMenu}]">
            <div class="top-block">
                <BButton class="order-now" @click="openOrderNow(true)">Order Now</BButton>

                <div class="text-phone">
                    <ClientOnly>
                        <template #fallback>
                            <a data-calltrk-noswap href="tel:(888) 816-7468">(888) 816-7468</a>
                        </template>
                        <a href="tel:(888) 816-7468">(888) 816-7468</a>
                    </ClientOnly>
                </div>

                <div class="text-email">
                    <a href="mailto:info@flameoffcoatings.com?bcc=flameoffdev@gmail.com&subject=[flameoffcoatings.com] General">info@flameoffcoatings.com</a>
                </div>
            </div>

            <BAccordion flush class="main-menu" id="main-menu">
                <BAccordionItem id="products-mobile">
                    <template #title>
                        Products
                    </template>
                    <ul class="list-inline">
                        <li class="list-inline-item">
                            <NuxtLink to="/intumescent-fire-resistant-paint">
                                Fire Barrier Paint
                            </NuxtLink>
                        </li>
                        <li class="list-inline-item">
                            <NuxtLink to="/fire-retardant-clear-spray">
                                FR Clear
                            </NuxtLink>
                        </li>
                    </ul>
                </BAccordionItem>
                <BAccordionItem id="substrates-mobile">
                    <template #title>
                        Substrates
                    </template>
                    <h6 class="subtitle">FIRE BARRIER PAINT</h6>
                    <ul class="list-inline">
                        <li class="list-inline-item">
                            <NuxtLink to="/applying-fire-rated-intumescent-paint-on-steel">
                                Steel
                            </NuxtLink>
                        </li>
                        <li class="list-inline-item">
                            <NuxtLink to="/applying-fire-rated-intumescent-paint-on-gypsum">
                                Gypsum
                            </NuxtLink>
                        </li>
                        <li class="list-inline-item">
                            <NuxtLink to="/applying-fire-rated-intumescent-paint-on-wood">
                                Wood
                            </NuxtLink>
                        </li>
                    </ul>
                    <h6 class="subtitle">FR CLEAR</h6>
                    <ul class="list-inline">
                        <li class="list-inline-item">
                            <NuxtLink to="/applying-fire-retardant-coatings-on-wood">
                                Wood
                            </NuxtLink>
                        </li>
                        <li class="list-inline-item">
                            <NuxtLink to="/applying-flame-retardant-coatings-on-fabrics">
                                Fabrics
                            </NuxtLink>
                        </li>
                    </ul>
                </BAccordionItem>
                <BAccordionItem id="about-mobile">
                    <template #title>
                        About
                    </template>
                    <ul class="list-inline">
                        <li class="list-inline-item">
                            <NuxtLink to="/about/company">
                                Company
                            </NuxtLink>
                        </li>
                        <li class="list-inline-item">
                            <NuxtLink to="/intumescent-paint-case-studies">
                                Case Studies
                            </NuxtLink>
                        </li>
                        <li class="list-inline-item">
                            <NuxtLink to="/articles">
                                Articles
                            </NuxtLink>
                        </li>
                        <li class="list-inline-item">
                            <NuxtLink to="/company-news">
                                Company News
                            </NuxtLink>
                        </li>
                    </ul>
                </BAccordionItem>
                <BAccordionItem id="contact-us-mobile" title="Contact Us">
                    <template #title>
                        <!-- We can't use <a> because slot content will be placed inside a <button> -->
                        <div @click="navigateTo('/contact-us')">
                            Contact Us
                        </div>
                    </template>
                </BAccordionItem>
                <BAccordionItem id="resources-mobile">
                    <template #title>
                        Resources
                    </template>
                    <ul class="list-inline">
                        <li class="list-inline-item">
                            <NuxtLink to="/steel-coverage-calculator">
                                Steel Coverage Calculator
                            </NuxtLink>
                        </li>
                        <li class="list-inline-item">
                            <NuxtLink to="/testing">
                                Testing
                            </NuxtLink>
                        </li>
                        <li class="list-inline-item">
                            <NuxtLink to="/leed">
                                LEED
                            </NuxtLink>
                        </li>
                        <li class="list-inline-item">
                            <NuxtLink to="/faq">
                                FAQ
                            </NuxtLink>
                        </li>
                        <li class="list-inline-item">
                            <NuxtLink to="/flameoff-partnership">
                                Partnership
                            </NuxtLink>
                        </li>
                        <li class="list-inline-item"><a href="https://flameoffgetcertified.tizbi.com/." target="_blank" rel="noreferrer noopener nofollow">Get Certified</a></li>
                        <li class="list-inline-item"><a href="https://lunchandlearn.flameoffcoatings.com" target="_blank" rel="noreferrer noopener nofollow">Lunch and Learn</a></li>
                        <li class="list-inline-item"><a href="https://www.youtube.com/@flameoffcoatingsinc5470/videos" target="_blank" rel="noreferrer noopener nofollow">Media Library</a></li>
                    </ul>
                </BAccordionItem>
            </BAccordion>

            <div class="bottom-block">
                <div class="sign-in-block" v-if="!isAuthenticated">
                    <NuxtLink to="/login" class="btn-sign-in">SIGN IN</NuxtLink>
                </div>
                <div class="sign-in-block" v-else>
                    <NuxtLink to="/customer/info">PROFILE</NuxtLink>
                    <a href="#" class="btn-logout" @click="logout">LOG OUT</a>
                </div>
                <div class="shopping-cart-block">
                    <NuxtLink to="/cart" class="cart-link">CART <span class="qty-wrap">(<span class="qty">{{ cartItemCount }}</span>)</span></NuxtLink>
                </div>
            </div>
        </div>

        <OrderModal ref="orderModal" @productAdded="initCustomer"></OrderModal>
        <QuoteModal ref="quoteModal"></QuoteModal>
    </header>
</template>

<script lang="ts" setup>
    import type { BootstrapModalRef } from '@/types/models/default-refs-types';
    import type { CartItemViewModel } from '@/types/models/cart/cart-item-view-model';
    import { useUserStore } from '@/stores/userStore';
    import { accountService, customerService, cartService } from '@/services';

    const { logout } = useAuth();
    const route = useRoute();
    const userStore = useUserStore();

    const orderModal = ref<BootstrapModalRef>();
    const quoteModal = ref<BootstrapModalRef>();

    const cartItems = ref<CartItemViewModel[]>([]);
    const customerId = ref<Nullable<number>>(null);
    const showFlyoutCart = ref(false);
    const activeMobileMenu = ref(false);

    const { swapContainer } = useSwap();

    const { $emitter } = useNuxtApp();

    onMounted(() => {
        swapContainer();
        initStickyHeader();
        initCustomer();

        $emitter.on('reloadCart', () => reloadCart());
        $emitter.on('openOrderNow', () => openOrderNow());
        $emitter.on('openQuote', () => openQuote());
    });

    async function initCustomer() {
        const userId = userStore.isAuthenticated ? await accountService.getId() : null;
        customerId.value = await customerService.getCustomerIdByUser(userId);
        reloadCart();
    }

    async function reloadCart() {
        cartItems.value = await cartService.getShoppingCart(customerId.value);
    }

    function resolveProductVariant(variantName: string) {
        const variant = variantName.match(/\(([^)]+)\)/);
        return variant ? variant[1] : variantName;
    }

    function openQuote() {
        quoteModal.value?.show(customerId.value);
    }

    function toogleFlyoutCart(showBlock: boolean) {
        showFlyoutCart.value = showBlock;
    }

    function openOrderNow(isMobile = false) {
        orderModal.value?.show(customerId.value);

        if (isMobile && activeMobileMenu.value)
            activeMobileMenu.value = !activeMobileMenu.value;
    }

    function initStickyHeader() {
        let nonStickyHeaderHeight = getNonStickyHeaderHeight();

        window.addEventListener("scroll", function () {
            processStickyHeader(nonStickyHeaderHeight);
        });

        window.addEventListener("resize", function () {
            nonStickyHeaderHeight = getNonStickyHeaderHeight();
            processStickyHeader(nonStickyHeaderHeight);
        });
    }

    function getNonStickyHeaderHeight() {
        const navbar = document.querySelector(".bg-default.top-bar");
        const splashScreen = document.querySelector(".splash-screen");

        const isNavbarVisible = navbar && window.getComputedStyle(navbar).display !== 'none';

        if (isNavbarVisible)
            return splashScreen ? 115 : 85;

        return splashScreen ? 72 : 50;
    }

    function processStickyHeader(nonStickyHeaderHeight: number) {
        const bodyStore = document.getElementById('body-store');

        if (!bodyStore) return;

        if (bodyStore.classList.contains('sticky-header-denied')) return;

        if (window.scrollY > nonStickyHeaderHeight - 50) {
            bodyStore.style.marginTop = `${nonStickyHeaderHeight}px`;
            bodyStore.classList.add('sticky-header-mode');
            bodyStore.classList.add('sticky-header-active');
        } else {
            bodyStore.style.marginTop = '0px';
            bodyStore.classList.remove('sticky-header-mode');
            bodyStore.classList.remove('sticky-header-active');
        }
    }

    const isAuthenticated = computed(() => {
        return userStore.isAuthenticated;
    });

    const user = computed(() => {
        return userStore.userInfo;
    });

    const isStoreIndex = computed(() => {
        return route.path === '/';
    });

    const cartItemCount = computed(() => {
        return cartItems.value?.reduce((sum, item) => sum + item.quantity, 0);
    });

    const cartSubTotal = computed(() => {
        return cartItems.value?.reduce((sum, item) => sum + (item.price * item.quantity), 0);
    });

    watch(isAuthenticated, async () => await initCustomer());

    watch(() => route.path, () => activeMobileMenu.value = false);
</script>

<style lang="scss">
    @import '@/assets/styles/bootstrap/common';
    @import "bootstrap/scss/_badge";
    @import "bootstrap/scss/nav";
    @import "bootstrap/scss/navbar";
    @import "bootstrap/scss/transitions";
    @import "bootstrap/scss/accordion";

    @import "@/assets/styles/_variables.scss";
    @import "@/assets/styles/_mixins.scss";
    @import "@/assets/styles/_overlay-menu.scss";
    @import "@/assets/styles/_contact.scss";

    .bg-default {
        border: none;
        border-radius: 0;
        position: relative;
        width: 100%;
        color: #fff;
        padding: 0;

        .cart-link-item {
            .cart-link {
                @media (max-width: 380px) {
                    display: none;
                }

                @include media-breakpoint-up(lg) {
                    display: none;
                }

                @media (max-width: 767.98px) {
                    .header-icon {
                        color: #fff;
                        font-size: 20px !important;

                        .bar-badge {
                            left: 30px;
                        }
                    }
                }

                position: relative;
                font-size: 16px;
                width: 50px;

                .badge {
                    background-color: $highlight-color;
                    color: #fff;
                    position: absolute;
                    top: 4px;
                    left: calc(50% + 5px);
                    font-weight: normal;
                    font-size: 10px;
                    padding: 3px 5px;
                }

                .bar-badge {
                    background-color: $highlight-color;
                    color: #fff;
                    position: absolute;
                    top: 7px;
                    left: calc(50% + 2px);
                    font-weight: normal;
                    font-size: 10px;
                    height: 18px;
                    padding-right: 0.6em;
                    padding-left: 0.6em;
                }
            }
        }

        &.top-bar {
            @include font-roboto-regular(15px);
            position: absolute;
            top: 0;
            left: 0;
            margin: 0;
            min-height: auto;
            height: 35px;
            background-color: #29303c;
            display: none;

            @include media-breakpoint-up(lg) {
                display: block;
            }

            .navbar-nav {
                & > .nav-item {
                    .nav-link,
                    .btn-link {
                        color: #C7C7C7;
                        padding: 0 15px;
                        line-height: 35px;
                        font-size: 14px;
                        text-decoration: none;

                        &:hover, &:focus {
                            color: darken(#FFF, 5%);
                        }
                    }
                }

                .dropdown-menu {
                    top: 32px;
                }
            }

            .login-item {
                display: none;

                @include media-breakpoint-up(md) {
                    display: block;
                }
            }
        }

        &.header {
            @include font-roboto-medium(16px);
            background: $brand-color;
            margin-bottom: 0;

            @include media-breakpoint-up(lg) {
                margin-top: 35px;
            }

            .btn {
                @include font-roboto-medium(16px);
            }

            & > .container {
                @include media-breakpoint-down(lg) {
                    max-width: 100%;
                }

                height: 100%;
                margin-right: auto;
                margin-left: auto;
                padding-left: 15px;
                padding-right: 15px;
            }

            .navbar-header {
                float: left;
            }

            .navbar-brand {
                display: inline-block;
                transition: none !important;
                margin-left: 15px !important;
                margin-top: 10px;
                width: 147px;
                height: 36px;
                background: url(/images/logo-sticky.webp) 0 0 no-repeat;
                background-size: contain;

                @media (-webkit-min-device-pixel-ratio: 1.25) {
                    background-image: url(/images/logo-sticky@2x.webp);
                }

                @media (-webkit-min-device-pixel-ratio: 3) {
                    background-image: url(/images/logo-sticky@3x.webp);
                }
            }

            .text-phone {
                @include media-breakpoint-up(lg) {
                    display: none;
                }

                a {
                    color: #fff;
                    font-weight: normal;
                    padding-right: 15px;

                    svg {
                        display: none;
                    }

                    @include media-breakpoint-down(md) {
                        margin-right: 5px;

                        svg {
                            display: block;
                            width: 28px !important;
                            height: 28px !important;
                            position: relative;
                            top: -4px;

                            path {
                                transition: ease-in 0.2s all;
                                fill: #FFF;
                            }
                        }

                        span:not(.nuxt-icon) {
                            display: none;
                        }
                    }
                }
            }

            .order-now-item {
                display: none !important;

                @media (min-width: 499px) {
                    display: inline-block !important;
                }

                .order-now {
                    @include font-source-sans-pro-regular(16px);
                    margin-right: 15px;
                    font-weight: 600;
                    padding: 5px 10px;
                    line-height: 17px;
                    min-width: auto;
                    transition-property: background-color;

                    @include media-breakpoint-up(lg) {
                    }

                    &:hover, &:focus {
                        background-color: #990303;
                        color: #fff;
                    }
                }
            }

            @include media-breakpoint-up(lg) {
                .navbar-brand {
                    margin-top: -17px;
                    height: 53px;
                    background: url(/images/logo.webp) 0 0 no-repeat;
                    background-size: contain;

                    @include media-breakpoint-up(md) {
                        margin-left: 0 !important;
                    }

                    @media (-webkit-min-device-pixel-ratio: 1.25) {
                        background-image: url(/images/logo@2x.webp);
                    }

                    @media (-webkit-min-device-pixel-ratio: 3) {
                        background-image: url(/images/logo@3x.webp);
                    }
                }
            }

            .navbar-nav {
                display: none;

                &.primary-nav {
                    @include media-breakpoint-up(lg) {
                        display: flex;
                    }
                }

                &.navbar-right {
                    display: flex;
                    flex-direction: row;
                    align-items: center;

                    @include media-breakpoint-up(md) {
                        margin-right: -15px;
                        margin-left: auto;
                    }

                    @include media-breakpoint-down(lg) {
                        margin: 0 30px 0 0;
                    }
                }

                & > .nav-item .nav-link {
                    line-height: 20px;

                    &:not([class~="btn-primary"]) {
                        color: #fff;
                        text-transform: uppercase;
                        padding: 14px 8px;
                        height: 50px;
                        outline: none;
                        border: none;

                        @include media-breakpoint-down(lg) {
                            padding-left: 15px;
                            padding-right: 15px;
                        }

                        &:hover, &:focus {
                            color: #fff !important;
                        }
                    }

                    &.active {
                        color: #fff !important;
                        border-bottom: 2px solid $highlight-color;
                    }
                }
            }

            &.navbar-index {
                background-color: transparent;
                margin-top: 0;

                @include media-breakpoint-up(lg) {
                    margin-top: 65px;
                }

                .navbar-brand {
                    height: 53px;
                    background: url(/images/logo.webp) 0 0 no-repeat;
                    background-size: contain;

                    @media (-webkit-min-device-pixel-ratio: 1.25) {
                        background-image: url(/images/logo@2x.webp);
                    }

                    @media (-webkit-min-device-pixel-ratio: 3) {
                        background-image: url(/images/logo@3x.webp);
                    }
                }

                .navbar-nav.navbar-right {
                    @include media-breakpoint-down(md) {
                        margin-top: 0px;
                    }
                }
            }
        }
        /* dropdowns */
        .navbar-nav {
            .open a {
                background-color: transparent !important;
            }

            .dropdown {
                & > .active {
                    color: #fff !important;
                    border-bottom: 2px solid $highlight-color;
                }
            }

            .dropdown-menu {
                a.active {
                    color: $highlight-color;
                }
            }

            .dropdown-menu {
                @media (min-width: 768px) {
                    width: auto;
                }

                background-color: rgb(255, 255, 255);
                border: none;
                box-shadow: 0px 0px 3px rgba(25, 25, 25, 0.3);
                top: 46px;
                left: 50%;
                right: auto;
                transform: translate(-50%, 0);
                margin: 0px;
                position: absolute;
                width: 100%;
                border-radius: 3px;

                .dropdown-item {
                    @include font-roboto-regular(16px);
                    color: #333;
                    background-color: transparent !important;

                    &:hover, &:focus {
                        color: $highlight-color;
                    }
                }

                .divider {
                    margin: 4px 0;
                    background-color: #edeff3;
                }

                .dropdown-header {
                    text-overflow: ellipsis;
                    white-space: nowrap;
                    overflow: hidden;
                    max-width: 185px;
                    font-weight: normal;
                    display: inline-block;
                }

                &.multi-column {
                    min-width: max-content;

                    .dropdown-header {
                        font-size: 12px;
                        min-width: 160px;
                    }

                    .dropdown-item {
                        --bs-dropdown-item-padding-y: 0.5rem;
                    }
                }
            }
        }
    }

    .sticky-header-mode {
        .bg-default.header {
            background-color: $brand-color;
            height: 50px;
            position: fixed;
            top: 0;
            left: 0;
            margin-top: 0;
            opacity: 0;
            z-index: -1;

            .navbar-brand {
                height: 36px;
                background: url(/images/logo-sticky.webp) 0 0 no-repeat;
                background-size: contain;
                margin-top: 10px;
                margin-right: unset;

                @media (-webkit-min-device-pixel-ratio: 1.25) {
                    background-image: url(/images/logo-sticky@2x.webp);
                }

                @media (-webkit-min-device-pixel-ratio: 3) {
                    background-image: url(/images/logo-sticky@3x.webp);
                }
            }

            .navbar-nav {
                .text-phone {
                    @include media-breakpoint-up(xl) {
                        display: block;
                    }
                }

                .cart-link {
                    @include media-breakpoint-up(xl) {
                        display: block;
                    }
                }
            }
        }

        &.sticky-header-active {
            .bg-default.header {
                opacity: 1;
                z-index: 99;
            }
        }

        &.sticky-header-denied {
            .bg-default.header {
                opacity: 0;
                z-index: -1;
            }
        }

        .overlay-menu-toggle.navbar-index {
            top: 17px !important;
        }

        .navbar-index.header {
            transition: background-color 0.2s cubic-bezier(.3,.73,.3,.74);
        }

        &.sticky-header-active .navbar-index.header {
            margin-top: 0;

            .navbar-nav.navbar-right {
                @include media-breakpoint-down(lg) {
                    margin-top: 0;
                }
            }
        }
    }

    #topcartlink {
        display: block;
        max-width: 400px;

        a i {
            padding: 0px 5px 0px 0px;
            text-align: center;
            width: 100%;
            font-size: 16px;
        }
    }

    @media all and (min-width: 992px) {

        #topcartlink {
            display: inline-block;
            width: auto;
            margin: 0;
            margin: 0px 0px 0px 16px;
            padding: 0;
        }

        .flyout-cart {
            position: fixed;
            top: 30px;
            left: 62%;
            z-index: 100;
            width: 300px;
            box-shadow: 0 0 2px rgba(0, 0, 0, 0.25);
            background-color: #fff;
            display: none;

            &.active {
                display: block;
            }
        }

        .mini-shopping-cart {
            padding: 12px;
            text-align: left;
            font-size: 12px;
            color: $body-secondary-color;

            .count {
                padding: 5px 0 15px;
                color: #444;

                a {
                    margin: 0 2px;
                    font-weight: bold;
                    color: #444;
                }
            }

            .items {
                border-top: 1px dashed #ddd;
                border-bottom: 1px dashed #ddd;
                margin: 0 0 10px;
            }

            .item {
                overflow: hidden;
                padding: 10px 0;
                border-bottom: 1px dashed #ddd;

                &:last-child {
                    border-bottom: none;
                }
            }

            .picture {
                float: left;
                width: 70px;
                text-align: center;

                a {
                    display: block;
                    position: relative;
                    overflow: hidden;

                    &:before {
                        content: "";
                        display: block;
                        padding-top: 100%;
                    }
                }

                img {
                    position: absolute;
                    top: 0;
                    right: 0;
                    bottom: 0;
                    left: 0;
                    margin: auto;
                    max-width: 100%;
                }

                + .product {
                    margin: 0 0 0 80px;
                }
            }

            .name {
                margin: 0 0 10px;
                font-size: 14px;
            }

            .attributes {
                margin: 5px 0;
                color: #444;
            }

            .totals {
                margin: 0 0 12px;
                font-size: 14px;
                text-align: right;

                strong {
                    font-size: 16px;
                    color: #444;
                }
            }

            .buttons {
                margin: 0 0 5px;
                text-align: right;
            }

            input[type="button"] {
                display: inline-block;
                border: none;
                padding: 10px 20px;
                font-size: 12px;
                color: #fff;
                text-transform: uppercase;
                margin: 0;
            }
        }
    }
</style>